import React from 'react';
import { Section, Image } from 'components';
import * as styles from './styles.module.scss';

const TwoColsTextImage = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    two_cols_text_image_title: title,
    two_cols_text_image_content: content,
    two_cols_text_image_image: image,
  } = primary;

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="TwoColsTextImage">
      <div>
        {title && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
        {content && <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.content} />}
      </div>
      <Image image={image} className={styles.image} />
    </Section>
  );
};

export default TwoColsTextImage;
