import React from 'react';
import { Footer, Header, SEO, NoJs, WmAscii } from 'components';
import '@fontsource/inter';
import '@fontsource/inter/700.css';
import '@fontsource/inter/900.css';
import '../../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const { children, customSEO, seo } = props;
  return (
    <>
      <NoJs />
      <WmAscii />
      <Header />
      <main>
        {!customSEO && <SEO {...seo} />}
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
