import React from 'react';
import { Section, Link } from 'components';
import * as styles from './styles.module.scss';

const Wysiwyg = (props) => {
  const { data } = props;
  const { primary } = data;
  const { wysiwyg_content: content, wysiwyg_cta_link_text: ctaLinkText, wysiwyg_cta_link_slug: ctaLinkSlug } = primary;

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="Wysiwyg">
      {content && <div dangerouslySetInnerHTML={{ __html: content.html }} className={styles.content} />}
      <div className={styles.ctaContainer}>
        {ctaLinkSlug?.url && (
          <Link to={`${ctaLinkSlug?.url}`} className="button secondary">
            {ctaLinkText?.text}
          </Link>
        )}
      </div>
    </Section>
  );
};

export default Wysiwyg;
