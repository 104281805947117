import React from 'react';
import { Section, Image } from 'components';
import * as styles from './styles.module.scss';

const ImageGrid = (props) => {
  const { data } = props;
  const { primary, items: gridImages } = data;
  const { image_grid_title: title, image_grid_subtitle: subtitle } = primary;

  return (
    <Section className={styles.section} sliceName="ImageGrid">
      <div className={styles.container}>
        {title && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
        {subtitle?.text && <div dangerouslySetInnerHTML={{ __html: subtitle?.html }} className={styles.subtitle} />}
      </div>
      <div className={styles.gridImagesContainer}>
        {gridImages.length > 0 &&
          gridImages.map((gridImageData) => {
            const gridImage = gridImageData?.image_grid_image;
            const gridImageTitle = gridImageData?.image_grid_image_title;
            const gridImageSubtitle = gridImageData?.image_grid_image_subtitle;
            const gridImageContent = gridImageData?.image_grid_image_content;

            return (
              <div className={styles.gridImageContainer} key={gridImageTitle?.text}>
                {gridImage && <Image image={gridImage} className={styles.gridImage} />}
                {gridImageTitle && (
                  <div dangerouslySetInnerHTML={{ __html: gridImageTitle?.html }} className={styles.gridImageTitle} />
                )}
                {gridImageSubtitle && (
                  <div
                    dangerouslySetInnerHTML={{ __html: gridImageSubtitle?.html }}
                    className={styles.gridImageSubtitle}
                  />
                )}
                {gridImageContent && (
                  <div
                    dangerouslySetInnerHTML={{ __html: gridImageContent?.html }}
                    className={styles.gridImageContent}
                  />
                )}
              </div>
            );
          })}
      </div>
    </Section>
  );
};

export default ImageGrid;
