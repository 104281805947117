import React from 'react';
import { Image } from 'components';
import * as styles from './styles.module.scss';

const TestimonialCard = ({ data }) => {
  const content = data?.testimonial_content;
  const name = data?.testimonial_name;
  const about = data?.testimonial_about;
  const icon = data?.testimonial_icon;
  const companyLogo = data?.testimonial_company_logo;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.logoContainer}>
        {companyLogo && <Image image={companyLogo} className={styles.companyLogo} />}
      </div>
      {content && <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.content} />}
      <div className={styles.bottomRowContainer}>
        {icon && <Image image={icon} className={styles.icon} />}
        <div>
          {name && <div dangerouslySetInnerHTML={{ __html: name?.html }} className={styles.name} />}
          {about && <div dangerouslySetInnerHTML={{ __html: about?.html }} className={styles.about} />}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
