import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import EngFlag from '../../../images/flag-gb.svg';
import KorFlag from '../../../images/flag-ko.svg';
import ChevronDown from '../../../images/chevron-down-performid.svg';
import * as styles from './styles.module.scss';

const LanguageSelector = ({ className }) => {
  const [icon, setIcon] = useState(EngFlag);

  useEffect(() => {
    const cookieLangSettings = Cookies.get('language');

    if (!cookieLangSettings) {
      Cookies.set('language', `${process.env.GATSBY_SITE_LANG}`);
    }

    if (cookieLangSettings !== `${process.env.GATSBY_SITE_LANG}`) {
      if (cookieLangSettings === 'english') {
        window.location.assign(`${process.env.GATSBY_SITE_URL}`);
      }

      if (cookieLangSettings === 'korean') {
        window.location.assign(`${process.env.GATSBY_SITE_URL_KR}`);
      }

      Cookies.set('language', `${process.env.GATSBY_SITE_LANG}`);
    }

    if (process.env.GATSBY_SITE_LANG === 'korean') {
      return setIcon(KorFlag);
    }
    return setIcon(EngFlag);
  }, []);

  const options = [
    {
      language: 'English',
      flag: EngFlag,
    },
    {
      language: '한국어',
      flag: KorFlag,
    },
  ];

  const handleSelectChange = (e) => {
    if (e.target.value === '한국어') {
      return window.location.assign(`${process.env.GATSBY_SITE_URL_KR}`);
    }

    return window.location.assign(`${process.env.GATSBY_SITE_URL}`);
  };

  return (
    <div className={`${styles.selectContainer} ${className || undefined}`}>
      <img src={icon} alt="flag icon" className={styles.selectIcon} />
      <img src={ChevronDown} alt="down icon" className={styles.downIcon} />
      <select
        className={styles.select}
        onChange={(e) => handleSelectChange(e)}
        value={process.env.GATSBY_SITE_LANG === 'korean' ? '한국어' : 'english'}
      >
        {options.length > 0 &&
          options.map((option) => (
            <option key={option?.language} value={option?.language.toLowerCase()}>
              {option?.language}
            </option>
          ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
