import React from 'react';
import { Section, Image, Form, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const Contact = (props) => {
  const koreanLang = process.env.GATSBY_SITE_LANG === 'korean';

  const { data } = props;
  const { primary } = data;
  const { contact_title: title, contact_subtitle: subtitle, contact_image: image } = primary;

  const fields = [
    {
      type: 'text',
      name: 'name',
      placeholder: koreanLang ? '성명을 입력하세요' : 'Enter your full name',
      label: koreanLang ? '성명 *' : 'Name *',
      className: 'split-left',
      autoFocus: true,
      defaultValue: '',
      validation: { required: true },
      validationMessage: koreanLang ? '이름을 입력해주세요' : 'Please enter your name',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: koreanLang ? '이메일을 입력하세요' : 'Enter your email',
      label: koreanLang ? '이메일 *' : 'Email *',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: koreanLang ? '유효한 이메일 주소를 입력해주세요' : 'Please enter a vald email',
    },
    {
      type: 'text',
      name: 'subject',
      placeholder: koreanLang ? '목적을 입력하세요' : 'Overview of your enquiry',
      label: koreanLang ? '목적 *' : 'Subject *',
      className: 'split-left',
      autoFocus: true,
      defaultValue: '',
      validation: { required: true },
      validationMessage: koreanLang ? ' 목적을 입력해주세요' : 'Please enter a subject',
    },
    {
      type: 'textarea',
      name: 'message',
      placeholder: koreanLang ? '세부 사항을 자세히 입력하세요' : 'Let us know how we can help you',
      label: koreanLang ? '세부 사항 *' : 'Message *',
      className: 'split-left',
      autoFocus: true,
      defaultValue: '',
      validation: { required: true },
      validationMessage: koreanLang ? '세부 사항을 입력해주세요' : 'Please enter a message',
    },
  ];

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="Contact" noContainer>
      <OnVisible className={styles.notYetVisible} visibleClassName={styles.visible}>
        <div className={`container ${styles.contentContainer}`}>
          <div>
            {title && <div dangerouslySetInnerHTML={{ __html: title.html }} className={styles.title} />}
            {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle.html }} className={styles.subtitle} />}
          </div>
          <Form
            fields={fields}
            className={styles.form}
            formId="SDExXuyD"
            submitButtonText={koreanLang ? '제출하기' : 'Submit'}
          />
        </div>
        {image && <Image image={image} className={styles.image} />}
      </OnVisible>
    </Section>
  );
};

export default Contact;
