import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Logo, Link, FormNewsletter } from 'components';
import Linkedin from '../../../images/linkedin.svg';
import FooterGraphic from '../../../images/footer-graphic.svg';
import * as styles from './styles.module.scss';

const Footer = () => {
  const footerSettingsQuery = graphql`
    query {
      ...FooterSettings
    }
  `;
  const { footerSettings } = useStaticQuery(footerSettingsQuery);
  const { data } = footerSettings;
  const {
    contact_email: contactEmail,
    copyright_disclaimer: copyrightDisclaimer,
    linkedin,
    office_location_address_1: officeAddress1,
    office_location_address_2: officeAddress2,
  } = data;

  const footerLinks = [];
  [1, 2, 3, 4, 5, 6, 7, 8].forEach((count) => {
    footerLinks.push({
      footerNavLinkText: data[`footer_navigation_link_${count}_text`],
      footerNavLinkSlug: data[`footer_navigation_link_${count}_link`],
    });
  });

  return (
    <footer className={styles.footer}>
      <div className={`container ${styles.footerContainer}`}>
        <img src={FooterGraphic} className={styles.footerGraphic} alt="Footer graphic" />
        <div className={styles.container}>
          <div className={styles.footerLogoCol}>
            <Logo className={styles.footerLogo} />
            {officeAddress1 && (
              <div dangerouslySetInnerHTML={{ __html: officeAddress1?.html }} className={styles.address} />
            )}
            {officeAddress2 && (
              <div dangerouslySetInnerHTML={{ __html: officeAddress2?.html }} className={styles.address} />
            )}
            <div className={styles.contactContainer}>
              {contactEmail && (
                <Link to={`mailto:${contactEmail?.text}`} className={styles.contactEmail}>
                  {contactEmail?.text}
                </Link>
              )}
              {linkedin && (
                <Link to={`${linkedin.text}`} className={styles.linkedin}>
                  <img src={Linkedin} alt="Linkedin" />
                </Link>
              )}
            </div>
          </div>
          <div>
            <h5 className={styles.usefulLinks}>Useful Links</h5>
            <div className={styles.footerLinksContainer}>
              {footerLinks.length &&
                footerLinks.map((link) => {
                  const slug = link?.footerNavLinkSlug?.url;
                  const text = link?.footerNavLinkText?.text;

                  return (
                    text && (
                      <Link key={text} className={styles.link} to={`${slug}`}>
                        {text}
                      </Link>
                    )
                  );
                })}
            </div>
          </div>
          <FormNewsletter />
        </div>
        {copyrightDisclaimer && (
          <div dangerouslySetInnerHTML={{ __html: copyrightDisclaimer?.html }} className={styles.copyright} />
        )}
      </div>
    </footer>
  );
};

export default Footer;
