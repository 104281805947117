import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const MetricsCompact = (props) => {
  const { data } = props;
  const { primary } = data;
  const metrics = [];
  [1, 2, 3, 4].forEach((count) => {
    metrics.push({
      stat: primary[`metric_column_${count}_stat`],
      subtitle: primary[`metric_column_${count}_subtitle`],
    });
  });

  return (
    <Section className={styles.section} containerClassName={styles.sectionContainer} sliceName="MetricsCompact">
      <div className={styles.container}>
        {metrics.length > 0 &&
          metrics.map((metric) => {
            const stat = metric?.stat;
            const subtitle = metric?.subtitle;

            return (
              <div className={styles.contentContainer} key={stat.text}>
                {stat && <div dangerouslySetInnerHTML={{ __html: stat?.html }} className={styles.stat} />}
                {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle?.html }} className={styles.subtitle} />}
              </div>
            );
          })}
      </div>
    </Section>
  );
};

export default MetricsCompact;
