// extracted by mini-css-extract-plugin
export var jobsBoardTitleContainer = "styles-module--jobsBoardTitleContainer--3yL7a";
export var title = "styles-module--title--szvzo";
export var subtitle = "styles-module--subtitle--hsG_O";
export var jobGridContainer = "styles-module--jobGridContainer--Mo2OI";
export var jobCardContentContainer = "styles-module--jobCardContentContainer--c5jLy";
export var jobCardTopRowContainer = "styles-module--jobCardTopRowContainer--3Ihd1";
export var jobLabel = "styles-module--jobLabel--1Dw7t";
export var jobTitle = "styles-module--jobTitle--2JZSR";
export var jobSubtitle = "styles-module--jobSubtitle--1e7P_";
export var jobCardBottomRowContainer = "styles-module--jobCardBottomRowContainer--1SJiO";
export var jobDatePostedContainer = "styles-module--jobDatePostedContainer--1xK8c";
export var jobDatePosted = "styles-module--jobDatePosted--L2Q8i";