import React from 'react';
import { Section, Image } from 'components';
import * as styles from './styles.module.scss';
import SphereGraphic from '../../images/sphere-graphic.svg';
import QuarterSphereGraphic from '../../images/quarter-sphere-graphic.svg';

const HeaderSlice = (props) => {
  const { data } = props;
  const { primary } = data;

  const {
    has_testimonial: hasTestimonial,
    header_title: title,
    header_subtitle: subtitle,
    header_testimonial_hero_image: testimonialHeroImage,
    header_testimonial_icon: testimonialIcon,
    header_testimonial_content: testimonialContent,
    header_testimonial_author: testimonialAuthor,
  } = primary;

  return (
    <Section
      className={`${styles.section} ${hasTestimonial ? styles.testimonialSection : null}`}
      containerClassName={styles.container}
      sliceName="HeaderSlice"
    >
      {title && <div dangerouslySetInnerHTML={{ __html: title.html }} className={styles.title} />}
      {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle.html }} className={styles.subtitle} />}
      {hasTestimonial && (
        <div className={styles.testimonialContainer}>
          <Image image={testimonialHeroImage} className={styles.testimonialHeroImage} />
          <div className={styles.testimonial}>
            <Image image={testimonialIcon} className={styles.testimonialIcon} />
            <div className={styles.testimonialContentDivider}>
              {testimonialContent && (
                <div
                  dangerouslySetInnerHTML={{ __html: testimonialContent.html }}
                  className={styles.testimonialContent}
                />
              )}
              {testimonialAuthor && (
                <div
                  dangerouslySetInnerHTML={{ __html: testimonialAuthor.html }}
                  className={styles.testimonialAuthor}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {hasTestimonial ? (
        <div className={styles.graphicContainer}>
          <img src={QuarterSphereGraphic} alt="Sphere graphic" className={styles.quarterGraphic} />
        </div>
      ) : (
        <div className={styles.graphicContainer}>
          <img src={SphereGraphic} alt="Sphere graphic" className={styles.graphic} />
        </div>
      )}
    </Section>
  );
};

export default HeaderSlice;
