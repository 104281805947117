import React from 'react';
import { Form } from 'components';
import * as styles from './styles.module.scss';

const FormNewsletter = () => {
  const koreanLang = process.env.GATSBY_SITE_LANG === 'korean';

  const fields = [
    {
      type: 'text',
      name: 'firstName',
      placeholder: koreanLang ? '성명' : 'First Name',
      label: koreanLang ? '성명' : 'First name',
      className: 'split-left',
      autoFocus: true,
      defaultValue: '',
      validation: { required: true },
      validationMessage: koreanLang ? '이름을 입력해주세요' : 'Please enter your first name',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: koreanLang ? '이메일 주소' : 'Email Address',
      label: koreanLang ? '이메일 주소' : 'Email Address',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: koreanLang ? '유효한 이메일 주소를 입력해주세요' : 'Please enter a valid email',
    },
  ];

  return (
    <div className={styles.formNewsletter}>
      <h5 className={styles.formNewsletterTitle}>{koreanLang ? '뉴스레터 구독하기' : 'Subscribe to the newsletter'}</h5>
      <Form fields={fields} removeLabels submitButtonText={koreanLang ? '구독하기' : 'Subscribe'} formId="CzWmkbWl" />
    </div>
  );
};

export default FormNewsletter;
