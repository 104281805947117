// extracted by mini-css-extract-plugin
export var section = "styles-module--section--1sXdo";
export var whiteBg = "styles-module--whiteBg--1zZ7D";
export var offWhiteBg = "styles-module--offWhiteBg--3ddEL";
export var mapsContainer = "styles-module--mapsContainer--35i3v";
export var title = "styles-module--title--1iGfe";
export var mapAndLabelContainer1 = "styles-module--mapAndLabelContainer1--1Iwqz";
export var mapAndLabelContainer2 = "styles-module--mapAndLabelContainer2--3FEAQ";
export var map1Container = "styles-module--map1Container--pybs6";
export var map2Container = "styles-module--map2Container--1zXDB";
export var address1PopupContainer = "styles-module--address1PopupContainer--CqS74";
export var address2PopupContainer = "styles-module--address2PopupContainer--2mHpu";
export var officeLocation1Title = "styles-module--officeLocation1Title--ryWZQ";
export var officeLocation2Title = "styles-module--officeLocation2Title--3wKPP";
export var addressLabelContainer = "styles-module--addressLabelContainer--7rQr1";
export var addressLabel = "styles-module--addressLabel--3WtQH";
export var officeLocation1Address = "styles-module--officeLocation1Address--25c1_";
export var officeLocation2Address = "styles-module--officeLocation2Address--1YIgN";