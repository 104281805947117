// extracted by mini-css-extract-plugin
export var footer = "styles-module--footer--GS_9L";
export var footerContainer = "styles-module--footerContainer--2mIRm";
export var footerGraphic = "styles-module--footerGraphic--2IZw-";
export var footerLogo = "styles-module--footerLogo--3LecO";
export var container = "styles-module--container--2eBER";
export var footerLogoCol = "styles-module--footerLogoCol--1_Vwo";
export var sitemap = "styles-module--sitemap--3nF1j";
export var footerLinksContainer = "styles-module--footerLinksContainer--1lrrN";
export var contactContainer = "styles-module--contactContainer--jPWV5";
export var linkedin = "styles-module--linkedin--xrztw";
export var usefulLinks = "styles-module--usefulLinks--2FWye";
export var link = "styles-module--link--1lfjK";
export var address = "styles-module--address--3PzSy";
export var contactEmail = "styles-module--contactEmail--2iuYP";
export var copyright = "styles-module--copyright--2avfX";