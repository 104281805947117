import React from 'react';
import { Section, Link } from 'components';
import LocationIcon from '../../images/location.svg';
import CalendarIcon from '../../images/calendar.svg';
import * as styles from './styles.module.scss';

const JobsBoard = (props) => {
  const koreanLang = process.env.GATSBY_SITE_LANG === 'korean';

  const { data } = props;
  const { primary, items: jobs } = data;
  const { jobs_board_title: title, jobs_board_subtitle: subtitle, jobs_board_card_cta_linktext: jobCtaText } = primary;

  const formatDatePosted = (datePosted) => {
    if (!datePosted) {
      return koreanLang ? '하루 전' : 'A Day Ago';
    }

    if (datePosted === 'a day ago') {
      return koreanLang ? '하루 전' : 'A Day Ago';
    }

    if (datePosted?.includes('days')) {
      return koreanLang
        ? `${datePosted?.match(/[0-9]/g).join('')} 일 전`
        : `${datePosted?.match(/[0-9]/g).join('')} Days Ago`;
    }

    if (datePosted === 'a month ago') {
      return koreanLang ? '한달 전' : 'A Month Ago';
    }

    if (datePosted?.includes('months')) {
      return koreanLang
        ? `${datePosted?.match(/[0-9]/g).join('')} 개월 전`
        : `${datePosted?.match(/[0-9]/g).join('')} Months Ago`;
    }
  };

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="JobsBoard">
      <div className={styles.jobsBoardTitleContainer}>
        {title && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
        {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle?.html }} className={styles.subtitle} />}
      </div>
      <div className={styles.jobGridContainer}>
        {jobs.length > 0 &&
          jobs.map((job) => {
            const jobLabel = job?.jobs_board_card_top_row_label;
            const jobTitle = job?.jobs_board_card_title;
            const jobSubtitle = job?.jobs_board_card_subtitle;
            const jobSlug = job?.jobs_board_card_cta_link_slug;
            const jobDatePosted = job?.jobs_board_card_date_posted;

            return (
              <div className={styles.jobCardContentContainer} key={`${jobDatePosted}-${jobTitle?.text}`}>
                <div className={styles.jobCardTopRowContainer}>
                  <img src={LocationIcon} alt="Location icon" className={styles.locationIcon} />
                  {jobLabel && <div dangerouslySetInnerHTML={{ __html: jobLabel?.html }} className={styles.jobLabel} />}
                </div>
                {jobTitle && <div dangerouslySetInnerHTML={{ __html: jobTitle?.html }} className={styles.jobTitle} />}
                {jobSubtitle && (
                  <div dangerouslySetInnerHTML={{ __html: jobSubtitle?.html }} className={styles.jobSubtitle} />
                )}
                <div className={styles.jobCardBottomRowContainer}>
                  <div className={styles.jobDatePostedContainer}>
                    <img src={CalendarIcon} alt="Calendar icon" className={styles.calendarIcon} />
                    {jobDatePosted && <span className={styles.jobDatePosted}>{formatDatePosted(jobDatePosted)}</span>}
                  </div>
                  {jobSlug?.url && (
                    <Link to={`${jobSlug?.url}`} className="button secondary">
                      {jobCtaText?.text}
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </Section>
  );
};

export default JobsBoard;
