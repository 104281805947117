// extracted by mini-css-extract-plugin
export var section = "styles-module--section--1oucr";
export var container = "styles-module--container--AOUcd";
export var twoColsContainer = "styles-module--twoColsContainer--1_mbi";
export var oddTwoColsContainer = "styles-module--oddTwoColsContainer--2Ae6q";
export var col1Container = "styles-module--col1Container--2bYEz";
export var col2Container = "styles-module--col2Container--2HJkH";
export var title = "styles-module--title--2hwL0";
export var subtitle = "styles-module--subtitle--2CyP9";
export var notYetVisible = "styles-module--notYetVisible--1sPYt";
export var visible = "styles-module--visible--1SMuq";