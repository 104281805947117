import React from 'react';
import { Section, Image, Link, OnVisible } from 'components';
import Chevron from '../../images/icons/chevron';
import * as styles from './styles.module.scss';

const Uspgrid = (props) => {
  const {
    data: { primary },
  } = props;

  const {
    usp_grid_title: uspGridTitle,
    usp_1_title: usp1Title,
    usp_1_content: usp1Content,
    usp_1_icon: usp1Icon,
    usp_2_title: usp2Title,
    usp_2_content: usp2Content,
    usp_2_icon: usp2Icon,
    usp_cta_title: uspCtaTitle,
    usp_cta_content: uspCtaContent,
    usp_cta_link_text: uspCtaLinkText,
    usp_cta_link_slug: uspCtaLinkSlug,
    usp_cta_icon: uspCtaIcon,
  } = primary;

  const uspPoints = [
    {
      usp1Title,
      usp1Content,
      usp1Icon,
    },
    {
      usp2Title,
      usp2Content,
      usp2Icon,
    },
  ];

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="Uspgrid">
      <OnVisible className={styles.notYetVisible} visibleClassName={styles.visible}>
        {uspGridTitle && (
          <div dangerouslySetInnerHTML={{ __html: uspGridTitle?.html }} className={styles.uspGridTitle} />
        )}
        <div className={styles.contentContainer}>
          <div className={styles.uspsContainer}>
            {uspPoints.length &&
              uspPoints.map((point) => {
                const title = point?.usp1Title || point?.usp2Title;
                const content = point?.usp1Content || point?.usp2Content;
                const icon = point?.usp1Icon || point?.usp2Icon;

                return (
                  <div className={styles.uspContainer} key={icon?.url}>
                    <div className={styles.uspIconTitleContainer}>
                      {icon && (
                        <div className={styles.uspIconContainer}>
                          <Image image={icon} className={styles.uspIcon} />
                        </div>
                      )}
                      {title && (
                        <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.mobileTitle} />
                      )}
                    </div>
                    <div>
                      {title && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
                      {content && (
                        <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.content} />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={styles.uspCtaContainer}>
            <div className={styles.uspCtaBg}>
              <div className={styles.uspCtaIconTitleContainer}>
                {uspCtaIcon && (
                  <div className={styles.uspCtaIconContainer}>
                    <Image image={uspCtaIcon} className={styles.uspCtaIcon} />
                  </div>
                )}
                {uspCtaTitle && (
                  <div dangerouslySetInnerHTML={{ __html: uspCtaTitle?.html }} className={styles.uspCtaTitle} />
                )}
              </div>
              {uspCtaContent && (
                <div dangerouslySetInnerHTML={{ __html: uspCtaContent?.html }} className={styles.uspCtaContent} />
              )}
              {uspCtaLinkSlug?.url && (
                <Link to={`${uspCtaLinkSlug?.url}`} className={styles.uspCtaLink}>
                  {uspCtaLinkText?.text}
                  <Chevron color="#25C8B4" className={styles.uspCtaArrow} />
                </Link>
              )}
            </div>
          </div>
        </div>
      </OnVisible>
    </Section>
  );
};

export default Uspgrid;
