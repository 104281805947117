// extracted by mini-css-extract-plugin
export var section = "styles-module--section--uTNKH";
export var container = "styles-module--container--3H5wD";
export var contentContainer = "styles-module--contentContainer--ENMyt";
export var title = "styles-module--title--1mkUH";
export var subtitle = "styles-module--subtitle--1gDAQ";
export var statsGroupContainer = "styles-module--statsGroupContainer--3Ngp2";
export var statContainer = "styles-module--statContainer--3aNnX";
export var statIconContainer = "styles-module--statIconContainer--3bgIm";
export var statTitle = "styles-module--statTitle--sefhO";
export var statSubtitle = "styles-module--statSubtitle--1l3l8";
export var graphicContainer = "styles-module--graphicContainer--2Lbo1";
export var graphic = "styles-module--graphic--1hoSB";
export var notYetVisible = "styles-module--notYetVisible--1HUOJ";
export var visible = "styles-module--visible--263rd";