import React from 'react';
import { Section, Link } from 'components';
import * as styles from './styles.module.scss';

const Cta = (props) => {
  const { data } = props;
  const { primary } = data;
  const { cta_title: title, cta_link_text: ctaLinkText, cta_link_slug: ctaLinkSlug } = primary;

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="Cta">
      {title && <div dangerouslySetInnerHTML={{ __html: title.html }} className={styles.title} />}
      {ctaLinkSlug?.url && (
        <Link to={`${ctaLinkSlug?.url}`} className="button secondary">
          {ctaLinkText?.text}
        </Link>
      )}
    </Section>
  );
};

export default Cta;
