import React from 'react';
import { Link } from 'components';
import url from '../../../images/logo.svg';
import { siteName } from '../../../../config/website';
import * as styles from './styles.module.scss';

const Logo = ({ className }) => (
  <Link className={`${styles.logo} logo ${className || null}`} to="/">
    <img src={url} alt={siteName} />
    {siteName}
  </Link>
);

export default Logo;
