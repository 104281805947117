import React from 'react';
import { Image, Section, Link, OnVisible } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import SphereGraphic from '../../../images/sphere-graphic.svg';
import * as styles from './styles.module.scss';

const HomeSplashMetrics = () => {
  const homeSplashMetricsSettingsQuery = graphql`
    query {
      ...HomeSplashMetricsSettings
    }
  `;

  const { homeSplashMetricsSettings } = useStaticQuery(homeSplashMetricsSettingsQuery);
  const { data } = homeSplashMetricsSettings;
  const {
    home_splash_metrics_title: title,
    home_splash_metrics_subtitle: subtitle,
    home_splash_metrics_cta_text: ctaText,
    home_splash_metrics_cta_slug: ctaSlug,
  } = data;

  const statsGroup = [];
  [1, 2, 3, 4].forEach((count) => {
    statsGroup.push({
      statTitle: data[`home_splash_metrics_stat_title_${count}`],
      statSubtitle: data[`home_splash_metrics_stat_subtitle_${count}`],
      statIcon: data[`home_splash_metrics_stat_icon_${count}`],
    });
  });

  return (
    <Section className={styles.section} containerClassName={styles.container}>
      <OnVisible className={styles.notYetVisible} visibleClassName={styles.visible}>
        <div className={styles.contentContainer}>
          <div>
            {title && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
            {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle?.html }} className={styles.subtitle} />}
            {ctaSlug?.url && (
              <Link to={`${ctaSlug?.url}`} className="button secondary">
                {ctaText?.text}
              </Link>
            )}
          </div>
          <div className={styles.statsGroupContainer}>
            {statsGroup.length > 0 &&
              statsGroup.map((stat) => {
                const statTitle = stat?.statTitle;
                const statSubtitle = stat?.statSubtitle;
                const statIcon = stat?.statIcon;

                return (
                  <div className={styles.statContainer} key={statIcon?.url}>
                    <div className={styles.statIconContainer}>
                      {statIcon && <Image image={statIcon} className={styles.statIcon} />}
                    </div>
                    <div>
                      {statTitle && (
                        <div dangerouslySetInnerHTML={{ __html: statTitle?.html }} className={styles.statTitle} />
                      )}
                      {statSubtitle && (
                        <div dangerouslySetInnerHTML={{ __html: statSubtitle?.html }} className={styles.statSubtitle} />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className={styles.graphicContainer}>
          <img src={SphereGraphic} alt="Sphere graphic" className={styles.graphic} />
        </div>
      </OnVisible>
    </Section>
  );
};

export default HomeSplashMetrics;
