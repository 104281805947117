// extracted by mini-css-extract-plugin
export var section = "styles-module--section--34SWe";
export var testimonialSection = "styles-module--testimonialSection--2oseL";
export var container = "styles-module--container--1966X";
export var title = "styles-module--title--32QUI";
export var subtitle = "styles-module--subtitle--2i0xj";
export var testimonialContainer = "styles-module--testimonialContainer--P8Ved";
export var testimonialHeroImage = "styles-module--testimonialHeroImage--3xXND";
export var testimonial = "styles-module--testimonial--228iS";
export var testimonialIcon = "styles-module--testimonialIcon--3GbEC";
export var testimonialContent = "styles-module--testimonialContent--crKHD";
export var testimonialAuthor = "styles-module--testimonialAuthor--1kpli";
export var quarterGraphic = "styles-module--quarterGraphic--2Ez4N";
export var graphic = "styles-module--graphic--WHq-N";