export const trackEvent = (eventName, data = {}) => {
  if (typeof window === 'undefined') return null;
  const { dataLayer } = window;

  if (!dataLayer) return null;

  dataLayer.push({
    event: eventName || 'event',
    ...data,
  });
};
