import React, { useState, useEffect } from 'react';
import 'intersection-observer';
import { InView } from 'react-intersection-observer';

const OnVisible = ({
  as,
  className,
  visibleClassName,
  id,
  onClick = () => {},
  onChange = () => {},
  style,
  threshold,
  children,
}) => {
  const [visible, setVisible] = useState(false);

  const onChangeHandler = (isVisible) => {
    setVisible(isVisible);
    onChange(isVisible);
  };

  useEffect(() => {
    (async function loadPolyfills() {
      if (typeof window.IntersectionObserver === 'undefined') {
        await import('intersection-observer');
      }
    })();
  }, []);

  return (
    <InView
      as={as || 'div'}
      className={`${className || ''} ${visible ? `visible ${visibleClassName || ''}` : ''}`}
      style={style}
      id={id || ''}
      triggerOnce
      onClick={onClick}
      onChange={onChangeHandler}
      threshold={threshold || 0.5}
    >
      {children}
    </InView>
  );
};

export default OnVisible;
