import React from 'react';

const Chevron = ({ color, className }) => (
  <svg
    width="31"
    height="32"
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className || null}
  >
    <path
      d="M11.625 23.75L19.375 16L11.625 8.25"
      stroke={color || '#13164B'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Chevron;
