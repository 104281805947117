import React, { useEffect, useRef } from 'react';
import { Section } from 'components';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapLogo from '../../images/map-logo.svg';
import OfficeLocationIcon from '../../images/office-location.svg';
import * as styles from './styles.module.scss';

const OurOffices = (props) => {
  const koreanLang = process.env.GATSBY_SITE_LANG === 'korean';

  const { data } = props;
  const { primary } = data;
  const {
    our_offices_title: title,
    office_location_1_title: officeLocation1Title,
    office_location_1_address: officeLocation1Address,
    office_location_2_title: officeLocation2Title,
    office_location_2_address: officeLocation2Address,
    background_colour: backgroundColour,
  } = primary;

  const map1Container = useRef(null);
  const map1 = useRef(null);
  const map2Container = useRef(null);
  const map2 = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN;

    if (map1.current) return; // initialize map only once
    map1.current = new mapboxgl.Map({
      container: map1Container.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [151.21588778228275, -33.87443511412773],
      zoom: 16,
    });

    const marker1 = new mapboxgl.Marker({
      color: '#25C8B4',
    })
      .setLngLat([151.21588778228275, -33.87443511412773])
      .addTo(map1.current);

    if (map2.current) return; // initialize map only once
    map2.current = new mapboxgl.Map({
      container: map2Container.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [126.9038469308199, 37.51912064705763],
      zoom: 13,
    });

    const marker2 = new mapboxgl.Marker({
      color: '#25C8B4',
    })
      .setLngLat([126.9038469308199, 37.51912064705763])
      .addTo(map2.current);
  }, []);

  return (
    <Section
      className={`${styles.section} ${backgroundColour === 'white' ? styles.whiteBg : styles.offWhiteBg}`}
      containerClassName={styles.container}
      sliceName="OurOffices"
    >
      {title && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
      <div className={styles.mapsContainer}>
        <div className={styles.mapAndLabelContainer1}>
          <div className={styles.address1PopupContainer}>
            {officeLocation1Title && (
              <div
                dangerouslySetInnerHTML={{ __html: officeLocation1Title?.html }}
                className={styles.officeLocation1Title}
              />
            )}
            <div className={styles.addressLabelContainer}>
              <img src={OfficeLocationIcon} alt="Office location icon" />
              <span className={styles.addressLabel}>{koreanLang ? '위치' : 'Address'}</span>
            </div>
            {officeLocation1Address && (
              <div
                dangerouslySetInnerHTML={{ __html: officeLocation1Address?.html }}
                className={styles.officeLocation1Address}
              />
            )}
          </div>
          <div ref={map1Container} className={styles.map1Container} />
        </div>
        <div className={styles.mapAndLabelContainer2}>
          <div className={styles.address2PopupContainer}>
            {officeLocation2Title && (
              <div
                dangerouslySetInnerHTML={{ __html: officeLocation2Title?.html }}
                className={styles.officeLocation2Title}
              />
            )}
            <div className={styles.addressLabelContainer}>
              <img src={OfficeLocationIcon} alt="Office location icon" />
              <span className={styles.addressLabel}>{koreanLang ? '위치' : 'Address'}</span>
            </div>
            {officeLocation2Address && (
              <div
                dangerouslySetInnerHTML={{ __html: officeLocation2Address?.html }}
                className={styles.officeLocation2Address}
              />
            )}
          </div>
          <div ref={map2Container} className={styles.map2Container} />
        </div>
      </div>
    </Section>
  );
};

export default OurOffices;
